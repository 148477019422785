<template>
  <div class="personPage">
    <div class="info-title">
      <div class="t1">睡眠报告 <span>Sleep Report</span></div>
      <div class="t2">
        编号ID : <span>{{ row.id }}</span
        >姓名 : <span>{{ row.userName }}</span
        >性别 : <span>{{ row.sex | sexString }}</span
        >入住信息 : <span>{{ row.resourceName }}</span>
      </div>
    </div>
    <div class="info-choose">
      <div class="date">
        <datePicker
          :type="activeNav"
          :disableFlag="disableFlag"
          @rangeDateChange="rangeDateChange"
        ></datePicker>
      </div>
      <div class="tab">
        <ul>
          <li
            v-for="(item, i) in navList"
            :key="i"
            @click="tabChange(item)"
            :class="{ isActive: activeNav == item.id }"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="info-main">
      <div class="sleep">
        <div class="main-title"><span></span> 睡眠时长</div>
        <div class="chart-box">
          <div class="chart-detail">
            <div v-if="activeNav == 1">
              <custom-sleep :seriesData="seriesData"></custom-sleep>
            </div>
            <div v-if="activeNav != 1">
              <bar-sleep
                :itemColor="itemColor.sleep"
                :reportType="reportType"
                :optionData="barSleepData"
                :isMain="true"
              ></bar-sleep>
            </div>
          </div>
          <div class="msg-detail">
            <div v-if="activeNav == 1" class="sleep-day">
              <div class="day-l">
                <div>
                  上床时间：
                  <span>{{ sleepTimeStatistical.bedTime }}</span>
                </div>
                <div>
                  入睡时间：
                  <span>{{ sleepTimeStatistical.sleepTime }}</span>
                </div>
                <div>
                  睡醒时间：
                  <span>{{ sleepTimeStatistical.awakeTime }}</span>
                </div>
                <div>
                  起床时间：
                  <span>{{ sleepTimeStatistical.outBedTime }}</span>
                </div>
                <div>
                  睡眠时间：
                  <span>{{
                    timeCount(sleepTimeStatistical.sleepDuration)
                  }}</span>
                </div>
              </div>
              <div class="day-r">
                <div>
                  深睡时间：
                  <span>{{
                    timeCount(sleepStatistical.deepSleepDuration)
                  }}</span>
                </div>
                <div>
                  清醒时间：
                  <span>{{ timeCount(sleepStatistical.awakeDuration) }}</span>
                </div>
                <div>
                  浅睡时间：
                  <span>{{
                    timeCount(sleepStatistical.lightSleepDuration)
                  }}</span>
                </div>
                <div>
                  离床时间：
                  <span>{{ timeCount(sleepStatistical.offBedDuration) }}</span>
                </div>
              </div>
            </div>
            <div v-if="activeNav != 1" class="week-month">
              <div class="week-month-item">
                <div class="item-left">累计睡眠时间：</div>
                <div class="item-right">
                  {{ timeCount(sleepDuration) }}
                </div>
              </div>
              <div class="week-month-item">
                <div class="item-left">心率异常：</div>
                <div class="item-right">
                  {{ heartUnusualCount | unityCount }}
                </div>
              </div>
              <div class="week-month-item">
                <div class="item-left">呼吸异常：</div>
                <div class="item-right">
                  {{ breathUnusualCount | unityCount }}
                </div>
              </div>
              <div class="week-month-item">
                <div class="item-left">呼吸暂停：</div>
                <div class="item-right">
                  {{ breathDownCount | unityCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hb">
        <div class="heart">
          <div
            class="heart-title"
            :style="{ marginBottom: activeNav == 1 ? '31px' : 0 }"
          >
            <div class="main-title"><span></span> 心率趋势</div>
            <div class="msg-detail" v-if="activeNav == 1">
              <div>
                最高：
                <span>{{ heartStatistical.max | unityCountMinute }}</span>
              </div>
              <div>
                最低：
                <span>{{ heartStatistical.min | unityCountMinute }}</span>
              </div>
              <div>
                平均：
                <span>{{ heartStatistical.avg | unityCountMinute }}</span>
              </div>
              <div>
                异常：
                <span>{{ heartStatistical.unusual | unityCount }}</span>
              </div>
            </div>
          </div>
          <div class="chart-detail">
            <div v-if="activeNav == 1">
              <line-sleep :optionData="lineHeartData"></line-sleep>
            </div>
            <div v-if="activeNav != 1">
              <bar-sleep
                :itemColor="itemColor.heart"
                :reportType="reportType"
                :optionData="barHeartData"
              ></bar-sleep>
            </div>
          </div>
        </div>
        <div class="breath">
          <div class="breath-title">
            <div class="main-title"><span></span> 呼吸趋势</div>
            <div class="msg-detail" v-if="activeNav == 1">
              <div class="msg-detail-f">
                <div>
                  最高：
                  <span>{{ breathStatistical.max | unityCountMinute }}</span>
                </div>
                <div>
                  最低：
                  <span>{{ breathStatistical.min | unityCountMinute }}</span>
                </div>
                <div>
                  平均：
                  <span>{{ breathStatistical.avg | unityCountMinute }}</span>
                </div>
                <div>
                  异常：
                  <span>{{ breathStatistical.unusual | unityCount }}</span>
                </div>
              </div>
              <div class="msg-detail-s">
                <div>
                  呼吸暂停：
                  <span>{{ breathStatistical.downCount | unityCount }}</span>
                </div>
                <div>
                  持续时间：
                  <span>{{ breathStatistical.downDuration | unitySec }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-detail">
            <div v-if="activeNav == 1">
              <line-sleep :optionData="lineBreathData"></line-sleep>
            </div>

            <div v-if="activeNav != 1">
              <bar-sleep
                :itemColor="itemColor.breath"
                :reportType="reportType"
                :optionData="barBreathData"
              ></bar-sleep>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import datePicker from "./components/datePicker.vue";
import customSleep from "./components/cuostomSleep.vue";
import lineSleep from "./components/lineSleep.vue";
import barSleep from "./components/barSleep.vue";

const KEY = "sleepRowInfo";
const sleepTimeTotal = {
  bedTime: "00:00",
  sleepTime: "00:00",
  awakeTime: "00:00",
  outBedTime: "00:00",
  sleepDuration: 0,
};
const sleepStates = {
  awakeDuration: 0,
  offBedDuration: 0,
  deepSleepDuration: 0,
  lightSleepDuration: 0,
};
const breathStates = {
  max: 0,
  min: 0,
  avg: 0,
  unusual: 0,
  downCount: 0,
  downDuration: "0",
};
const heartStates = {
  max: 0,
  min: 0,
  avg: 0,
  unusual: 0,
};
const oneDay = 24 * 60 * 60 * 1000;
const WEEK = "week";
const MONTH = "month";
export default {
  name: "sleepReportInfo",
  components: {
    BTabs,
    BCardText,
    BTab,
    datePicker,
    customSleep,
    lineSleep,
    barSleep,
  },
  filters: {
    sexString: function (value) {
      return value == 0 ? "男" : "女";
    },
    unityCount: function (value) {
      if (!value) return 0;
      return `${value} 次`;
    },
    unityCountMinute: function (value) {
      if (!value) return 0;
      return `${value} 次/分`;
    },
    unitySec: function (value) {
      if (!value) return 0;
      return `${value} 秒`;
    },
  },
  data() {
    return {
      activeNav: "1",
      url: "tob/tenant/sleepReport/day/page",
      date: "",
      weekDate: "",
      monthDate: "",
      row: null,
      disableFlag: false,
      itemColor: {
        sleep: "#8989EF",
        heart: "#8CADFC",
        breath: "#CDA2F8",
      },
      reportType: "week",
      typeItem: {
        1: {
          typeName: "离床",
          typeColor: "#CB95F9",
        },
        2: {
          typeName: "清醒",
          typeColor: "#83B2FC",
        },
        3: {
          typeName: "浅睡",
          typeColor: "#B7B7F7",
        },
        4: {
          typeName: "深睡",
          typeColor: "#8989EF",
        },
        5: {
          typeName: "REM",
          typeColor: "#568FFB",
        },
      },
      navList: [
        { id: "1", name: "日报", url: "tob/tenant/sleepReport/day/page" },
        { id: "2", name: "周报", url: "tob/tenant/sleepReport/week/page" },
        { id: "3", name: "月报", url: "tob/tenant/sleepReport/month/page" },
      ],
      sleepTimeStatistical: sleepTimeTotal,
      sleepStatistical: sleepStates,
      breathStatistical: breathStates,
      heartStatistical: heartStates,
      sleepDuration: 0,
      heartUnusualCount: 0,
      breathUnusualCount: 0,
      breathDownCount: 0,
      seriesData: [],
      lineHeartData: {},
      lineBreathData: {},
      barSleepData: "",
      barHeartData: "",
      barBreathData: "",
    };
  },
  created() {
    if (this.$route.query.rowInfo && this.$route.query.rowInfo.id) {
      this.row = this.$route.query.rowInfo;
      window.sessionStorage.setItem(KEY, JSON.stringify(this.row));
    } else {
      this.row = JSON.parse(window.sessionStorage.getItem(KEY));
    }
  },
  beforeDestroy() {
    window.sessionStorage.removeItem(KEY);
  },
  methods: {
    tabChange(item) {
      this.activeNav = item.id;
      this.url = item.url;
      if (this.activeNav === "2") {
        this.reportType = WEEK;
      }
      if (this.activeNav === "3") {
        this.reportType = MONTH;
      }
    },
    rangeDateChange(date) {
      if (!date) {
        this.dataInit();
        return;
      }
      this.date = date;
      this.queryChartData();
    },
    dataInit() {
      this.disableFlag = false;
      this.sleepTimeStatistical = sleepTimeTotal;
      this.sleepStatistical = sleepStates;
      this.breathStatistical = breathStates;
      this.heartStatistical = heartStates;
      this.seriesData = [];
      this.lineBreathData = {};
      this.lineHeartData = {};
      this.sleepDuration = 0;
      this.heartUnusualCount = 0;
      this.breathUnusualCount = 0;
      this.breathDownCount = 0;
      this.barSleepData = "";
      this.barHeartData = "";
      this.barBreathData = "";
    },
    queryChartData() {
      this.disableFlag = true;
      let tempReportDate = this.date;
      if (this.activeNav === "3") {
        tempReportDate = tempReportDate.substring(0,tempReportDate.lastIndexOf("-")); // 查看月报只需要精确到月份即可
      }
      request
        .get(this.url, {
          personId: this.row.id,
          reportDate: tempReportDate,
        })
        .then((res) => {
          this.dataInit();
          if (res.data && res.data.success) {
            let resData = res.data.data.data;
            let trendData;
            if (this.activeNav == 1) {
              trendData = resData.length ? JSON.parse(resData[0].detail) : null;
            } else {
              trendData = resData[0];
            }
            console.log(trendData);
            if (trendData) {
              this.reportDataTrend(trendData);
            }
          }
        });
    },
    reportDataTrend(trendData) {
      if (this.activeNav == 1) {
        this.sleepStatistical = trendData.sleepStatistical;
        this.breathStatistical = trendData.breathStatistical;
        this.heartStatistical = trendData.heartStatistical;
        this.sleepTimeStatistical = {
          bedTime: this.stampFormat(trendData.bedTimeStamp),
          sleepTime: this.stampFormat(trendData.sleepTimeStamp),
          awakeTime: this.stampFormat(trendData.awakeTimeStamp),
          outBedTime: this.stampFormat(trendData.outBedTimeStamp),
          sleepDuration: trendData.sleepDuration,
        };
        this.customTrendDataFormat(trendData.sleepTrend);
        let heartTrend = this.onlyData(trendData.heartTrend);
        let breathTrend = this.onlyData(trendData.breathTrend);
        heartTrend.forEach((item) => {
          item.minute = this.timeFormat(item.minute);
        });
        this.lineHeartData = {
          xAxisData: heartTrend.map((item) => item.minute),
          seriesData: heartTrend.map((item) => {
            return item.heart;
          }),
          markData: heartTrend
            .filter((item) => item.warn)
            .map((item) => {
              return {
                xAxis: item.minute,
                yAxis: item.heart,
                warn: item.warn,
              };
            }),
        };
        breathTrend.forEach((item) => {
          item.minute = this.timeFormat(item.minute);
        });
        this.lineBreathData = {
          xAxisData: breathTrend.map((item) => item.minute),
          seriesData: breathTrend.map((item) => {
            return item.breath;
          }),
          markData: breathTrend
            .filter((item) => item.warn)
            .map((item) => {
              return {
                xAxis: item.minute,
                yAxis: item.breath,
                warn: item.warn,
              };
            }),
        };
      } else {
        this.sleepDuration = trendData.sleepDuration;
        this.heartUnusualCount = trendData.heartUnusualCount;
        this.breathUnusualCount = trendData.breathUnusualCount;
        this.breathDownCount = trendData.breathDownCount;
        this.barSleepData = trendData.sleepTrend;
        this.barHeartData = trendData.heartTrend;
        this.barBreathData = trendData.breathTrend;
      }
    },
    onlyData(data) {
      let map = new Map();
      for (let item of data) {
        if (!map.has(item.minute)) {
          map.set(item.index, item);
        }
      }
      return [...map.values()];
    },
    stampFormat(time) {
      return new Date(time).toLocaleTimeString().slice(0, -3);
    },
    timeFormat(time) {
      let hour = parseInt(time / 60);
      hour = hour > 9 ? hour : "0" + hour;
      let minute = time % 60;
      minute = minute > 9 ? minute : "0" + minute;
      return `${hour}:${minute}`;
    },
    timeCount(value) {
      const hourCount = parseInt(value / 60);
      const minuteCount = parseInt(value % 60);
      return `${hourCount} 小时 ${minuteCount} 分钟`;
    },
    customTrendDataFormat(trendData) {
      const serData = [];
      trendData.forEach((item, index) => {
        const curName = this.typeItem[item.type].typeName;
        let baseTime = item.minute;
        const surplusArr = trendData.slice(index + 1);
        let duration;
        const firstDifIndex = surplusArr.findIndex(
          (it) => it.type !== item.type
        );
        if (firstDifIndex === 0) {
          duration = trendData[index + 1].minute - item.minute;
        } else if (firstDifIndex === -1) {
          duration = trendData[trendData.length - 1].minute - item.minute;
        } else {
          duration = surplusArr[firstDifIndex - 1].minute - item.minute;
        }
        serData.push({
          name: curName,
          value: [5 - item.type, baseTime, (baseTime += duration), duration],
          itemStyle: {
            normal: {
              color: this.typeItem[item.type].typeColor,
            },
          },
        });
      });
      this.seriesData = [].concat(serData);
    },
  },
};
</script>

<style lang="scss">
.el-picker-panel {
  position: absolute !important;
  z-index: 9999 !important;
  transform-origin: center top;
}
</style>

<style lang="scss" scoped>
.personPage {
  .info-title {
    display: flex;
    justify-content: space-between;
    .t1 {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      span {
        font-weight: 300;
        font-size: 16px;
      }
    }
    .t2 {
      color: #6d6a7a;
      font-size: 16px;
      span {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        margin-right: 20px;
      }
    }
  }

  .info-choose {
    display: flex;
    justify-content: space-between;
    background-color: #e1dff4;
    margin: 16px 0;
    padding: 16px;
    height: 70px;

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      width: 280px;
      height: 40px;
      background: #ffffff;
      border-radius: 20px;
      padding-left: 5px;
      li {
        list-style: none;
        height: 30px;
        line-height: 30px;
        width: 90px;
        text-align: center;
        color: #333;
        font-weight: bold;
        cursor: pointer;
      }
      .isActive {
        height: 30px;
        line-height: 30px;
        color: #fff;
        background: #7569f0;
        border-radius: 20px;
        width: 90px;
        text-align: center;
      }
    }
  }
  .info-main {
    .main-title {
      color: #333333;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 9px;
      span {
        display: inline-block;
        width: 3px;
        height: 18px;
        background: #7468f0;
        vertical-align: middle;
      }
    }
    .chart-detail {
      // margin-top: 20px;
      flex: 1;
    }
    .sleep {
      background: #ffffff;
      box-shadow: 0px 2px 16px -1px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      margin-bottom: 16px;
      padding: 20px;
      padding-bottom: 57px;
      padding-right: 42px;
      .chart-box {
        display: flex;
        justify-content: space-between;
        .msg-detail {
          background-color: #f6f5fc;
          border-radius: 3px;
          font-size: 16px;
          padding: 32px 24px;
          text-align: left;
          min-width: 270px;
          .week-month {
            color: #333;
            // text-align: center;
            margin-top: 35px;
            .week-month-item {
              display: flex;
              margin-bottom: 25px;
              .item-left {
                width: 120px;
                text-align: end;
              }
              .item-right {
                font-weight: bold;
              }
            }
          }
          .sleep-day {
            display: flex;
            justify-content: space-around;
            .day-l {
              border-right: 1px dashed #dfe2ee;
              padding-right: 20px;
              div {
                margin-bottom: 20px;
              }
            }
            .day-r {
              padding-left: 20px;
              div {
                margin-bottom: 20px;
              }
            }
            span {
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
    }
    .hb {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .heart {
        background: #ffffff;
        box-shadow: 0px 2px 16px -1px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        width: 49%;
        margin: 0 10px 0 0;
        padding: 20px 16px;
        margin-bottom: 16px;
        .heart-title {
          display: flex;
          justify-content: space-between;
          .msg-detail {
            background-color: #f6f5fc;
            border-radius: 3px;
            font-size: 16px;
            padding: 16px;
            text-align: center;
            display: flex;
            div {
              margin-right: 10px;
            }
            span {
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
      .breath {
        background: #ffffff;
        box-shadow: 0px 2px 16px -1px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        width: 49%;
        margin: 0 0 0 10px;
        padding: 20px;
        margin-bottom: 16px;
        .breath-title {
          display: flex;
          justify-content: space-between;
          .msg-detail {
            background-color: #f6f5fc;
            border-radius: 3px;
            font-size: 16px;
            padding: 16px;
            text-align: center;

            .msg-detail-f {
              display: flex;
              justify-content: end;
              div {
                margin-bottom: 5px;
                margin-right: 10px;
                text-align: left;
              }
            }
            .msg-detail-s {
              display: flex;
              justify-content: end;

              div {
                margin-right: 10px;
                text-align: left;
              }
            }
            div:last-child {
              margin-right: 0;
            }
            span {
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="date-picker">
    <!-- 日 -->
    <div v-if="type == 1">
      <el-date-picker
        v-model="defaultRangeValue"
        @change="rangeDateChange"
        :picker-options="pickerOptions"
        :disabled="disableFlag"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>
    <!-- 周 -->
    <div v-if="type == 2">
      <el-date-picker
        v-model="defaultRangeValue"
        @change="rangeDateChange"
        :picker-options="pickerOptions"
        :disabled="disableFlag"
        type="week"
        format="yyyy 第 WW 周"
        placeholder="选择周"
      >
      </el-date-picker>
    </div>
    <!-- 月 -->
    <div v-if="type == 3">
      <el-date-picker
        v-model="defaultRangeValue"
        @change="rangeDateChange"
        :picker-options="pickerOptions"
        :disabled="disableFlag"
        value-format="yyyy-MM-dd"
        type="month"
        placeholder="选择月"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
const oneDay = 24 * 60 * 60 * 1000;
export default {
  props: {
    type: String,
    disableFlag: Boolean,
  },
  data() {
    return {
      defaultRangeValue: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        firstDayOfWeek: 1,
      },
    };
  },
  watch: {
    type(val) {
      if (val) {
        this.getcurModel(val);
      }
    },
  },
  created() {
    this.getcurModel(1);
  },
  methods: {
    rangeDateChange(e) {
      this.defaultRangeValue = e;
      if (this.type == 2) {
        const isMonday = new Date(e).getDay() - 1;
        const timeStamp = e.getTime ? e.getTime() : new Date(e).getTime();
        this.defaultRangeValue = this.timeFun(timeStamp - isMonday * oneDay);
      }
      this.$emit("rangeDateChange", this.defaultRangeValue);
    },
    timeFun(unixtimestamp) {
      // 时间戳转为yy-mm-dd hh:mm:ss
      const timestamp = new Date(unixtimestamp);
      const year = 1900 + timestamp.getYear();
      const month = "0" + (timestamp.getMonth() + 1);
      const date = "0" + timestamp.getDate();
      return (
        year +
        "-" +
        month.substring(month.length - 2, month.length) +
        "-" +
        date.substring(date.length - 2, date.length)
      );
    },
    getcurModel(type) {
      const curDate = new Date();
      const timeStamp = new Date().getTime();
      if (type == 1) {
        this.defaultRangeValue = this.timeFun(timeStamp - oneDay);
      }
      if (type == 2) {
        const weekDiff = new Date(
          timeStamp - (curDate.getDay() - 1 + 7) * oneDay
        );
        this.defaultRangeValue = this.timeFun(weekDiff);
      }
      if (type == 3) {
        const curYear = curDate.getFullYear();
        let curMonth = curDate.getMonth();
        curMonth = curMonth > 9 ? curMonth : "0" + curMonth;
        this.defaultRangeValue = `${curYear}-${curMonth}-01`;
      }
      this.rangeDateChange(this.defaultRangeValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  ::v-deep.el-input__inner {
    background-color: transparent;
    border-radius: 20px;
    border: none;
    color: #333333;
  }
  ::v-deep.el-input__prefix,
  .el-input__suffix {
    height: 0;
  }
}
</style>

<template>
  <e-charts
    autoresize
    style="width: 100%; height: 300px"
    :options="options"
    ref="myChart"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axis";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/custom";
import theme from "@/@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

const categories = ["REM", "深睡", "浅睡", "清醒", "离床"];

export default {
  components: {
    ECharts,
  },
  props: {
    seriesData: {
      type: Array,
      default: null,
    },
  },
  watch: {
    seriesData: {
      handler(newVal, oldVal) {
        this.seriesData = newVal;
        this.options.series[0].data = this.seriesData;
      },
    },
  },
  data() {
    const that = this;
    return {
      options: {
        tooltip: {
          backgroundColor: "rgba(255, 255, 255)",
          textStyle: {
            color: "#000",
          },
          extraCssText: "box-shadow: 0 0 21px rgba(64, 54, 179, 0.19)",
          formatter: function (params) {
            let start = that.timeFormat(params.value[1]);
            let end = that.timeFormat(params.value[2]);
            let timeDur = `${start} - ${end}`;
            return `${params.name}: <br/> ${start} - ${end}`;
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "3%",
          bottom: "7%",
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            interval: 999,
            showMinLabel: true,
            showMaxLabel: true,
            formatter: function (val) {
              return that.timeFormat(val);
            },
          },
        },
        yAxis: {
          data: categories,
          type: "category",
          splitLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            fontFamily: "Microsoft YaHei",
          },
        },
        series: [
          {
            type: "custom",
            renderItem: this.renderItem,
            itemStyle: {
              opacity: 0.8,
            },
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: this.seriesData,
          },
        ],
      },
    };
  },
  methods: {
    renderItem(params, api) {
      const categoryIndex = api.value(0);
      const start = api.coord([api.value(1), categoryIndex]);
      const end = api.coord([api.value(2), categoryIndex]);
      const height = api.size([0, 1])[1] * 0.6;
      const rectShape = ECharts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        }
      );
      return (
        rectShape && {
          type: "rect",
          transition: ["shape"],
          shape: rectShape,
          style: api.style(),
        }
      );
    },
    timeFormat(time) {
      let hour = parseInt(time / 60);
      hour = hour > 9 ? hour : "0" + hour;
      let minute = time % 60;
      minute = minute > 9 ? minute : "0" + minute;
      return `${hour}:${minute}`;
    },
  },
};
</script>

<template>
  <e-charts
    autoresize
    style="width: 100%; height: 300px"
    :options="options"
    ref="myChart"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axis";
import "echarts/lib/chart/bar";
import theme from "@/@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);
const weekXAxis = ["周一", "周二", "周三", "周四", "周五", "周六", "周七"];

export default {
  components: {
    ECharts,
  },
  props: {
    itemColor: {
      type: String,
    },
    isMain: {
      type: Boolean,
    },
    reportType: {
      type: String,
    },
    optionData: {
      type: String,
      default: null,
    },
  },
  watch: {
    optionData: {
      handler(newVal, oldVal) {
        this.optionData = newVal;
        this.trendFunc(this.optionData);
      },
    },
  },
  data() {
    const that = this;
    return {
      options: {
        color: this.itemColor,
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255)",
          textStyle: {
            color: "#000",
          },
          extraCssText: "box-shadow: 0 0 21px rgba(64, 54, 179, 0.19)",
        },
        grid: {
          top: "5%",
          left: this.isMain ? "5%" : "7%",
          right: "3%",
          bottom: "7%",
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            showMinLabel: true,
            showMaxLabel: true,
          },
          data: [],
        },
        yAxis: {
          type: "value",
          name: this.isMain ? "小时" : "次",
          nameLocation: "end",
          nameTextStyle: {
            padding: [0, 10, 0, 0],
            fontSize: 14,
            align: "right",
            color: "rgba(109, 106, 122, 1)",
          },
          nameGap: 0,
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
            },
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    timeFormat(time) {
      let hour = parseInt(time / 60);
      hour = hour > 9 ? hour : "0" + hour;
      let minute = time % 60;
      minute = minute > 9 ? minute : "0" + minute;
      return `${hour}:${minute}`;
    },
    trendFunc(data) {
      let trendData = [];
      let xAxisData = [];
      data.split(",").forEach((item) => {
        let arr = item.split("=");
        trendData.push(arr[1]);
        xAxisData.push(arr[0]);
      });
      this.options.xAxis.data = xAxisData.length > 7 ? xAxisData : weekXAxis;
      this.options.xAxis.axisLabel.interval = xAxisData.length > 7 ? 4 : 0;
      this.options.series[0].barWidth = xAxisData.length > 7 ? 10 : 35;
      this.options.series[0].data = trendData;
      this.options.series[0].itemStyle.barBorderRadius =
        xAxisData.length > 7 ? [5, 5, 0, 0] : [17, 17, 0, 0];
    },
  },
};
</script>

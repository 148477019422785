<template>
  <e-charts
    autoresize
    style="width: 100%; height: 300px"
    :options="options"
    ref="myChart"
    theme="theme-color"
    auto-resize
  />
</template>

<script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/axis";
import "echarts/lib/chart/line";
import theme from "@/@core/components/charts/echart/theme.json";

ECharts.registerTheme("theme-color", theme);

const symbolType = {
  1: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjY4NzU1MTg5NzMxIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjU4OTQiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MTIuMDU1OTY0IDczNC40MTM3ODhtLTUyLjYyMDE0OCAwYTUyLjYyMDE0OCA1Mi42MjAxNDggMCAxIDAgMTA1LjI0MDI5NiAwIDUyLjYyMDE0OCA1Mi42MjAxNDggMCAxIDAtMTA1LjI0MDI5NiAwWiIgZmlsbD0iI2Q4MWUwNiIgcC1pZD0iNTg5NSI+PC9wYXRoPjxwYXRoIGQ9Ik01MTAuNjUyNzYgNjMxLjYyOTA5OWE0My44NTAxMjMgNDMuODUwMTIzIDAgMCAxLTQzLjQ5OTMyMi00NC4yMDA5MjRsMi42MzEwMDctMzUwLjgwMDk4NEE0My44NTAxMjMgNDMuODUwMTIzIDAgMCAxIDUxMy4yODM3NjggMTkzLjEyNzg2OWE0My42NzQ3MjMgNDMuNjc0NzIzIDAgMCAxIDQzLjQ5OTMyMiA0NC4yMDA5MjRsLTIuNjMxMDA4IDM1MC44MDA5ODRBNDMuODUwMTIzIDQzLjg1MDEyMyAwIDAgMSA1MTAuNjUyNzYgNjMxLjYyOTA5OXoiIGZpbGw9IiNkODFlMDYiIHAtaWQ9IjU4OTYiPjwvcGF0aD48cGF0aCBkPSJNNTEyLjA1NTk2NCAxMDI0QTUxMS45OTQwMzYgNTExLjk5NDAzNiAwIDEgMSA4NzQuMDgyNTggMTQ5Ljk3OTM0OGE1MTEuOTk0MDM2IDUxMS45OTQwMzYgMCAwIDEtMzYyLjAyNjYxNiA4NzQuMDIwNjUyeiBtMC05MzYuMTEyNDI2YTQyNC4yOTM3OSA0MjQuMjkzNzkgMCAxIDAgMzAwLjExMDI0MiAxMjQuMDA4MTQ4QTQyMy45NDI5ODkgNDIzLjk0Mjk4OSAwIDAgMCA1MTIuMDU1OTY0IDg3Ljg4NzU3NHoiIGZpbGw9IiNkODFlMDYiIHAtaWQ9IjU4OTciPjwvcGF0aD48L3N2Zz4=",
  2: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjY4NzU1NjUzNDcyIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDExMDQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjM0NzQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzQuNSIgaGVpZ2h0PSIzMiI+PHBhdGggZD0iTTk2MS4zNjkgMTAyMC45ODZIMTQxLjA2M2MtNTUuMDEgMC05OS4yMDItMjEuNzAxLTEyMS4yNzItNTkuNTUyLTIyLjA3Ni0zNy44NS0xOS4zOS04Ny4yOTMgNy4zOTYtMTM1LjYwNmw0MTMuODktNzQ2LjQ4YzI2Ljk0MS00OC42NjYgNjcuMDA0LTc2LjU2MSAxMDkuODY2LTc2LjU2MSA0Mi44NjcgMCA4Mi44OSAyNy44NiAxMDkuOTA3IDc2LjQ4M2w0MTQuMzIzIDc0Ni42MzVjMjYuNzggNDguMzE0IDI5LjU1IDk3LjcyIDcuNDMzIDEzNS41NjUtMjIuMDM1IDM3Ljg1LTY2LjI2MyA1OS41MTYtMTIxLjIzNyA1OS41MTZ6TTU1MC45ODQgODEuMTQ4Yy0xMy42MjggMC0yOS4yMzkgMTMuNTk1LTQxLjg5NCAzNi4zNjJMOTUuMjAyIDg2NC4wNjdjLTEyLjk2OCAyMy40My0xNi4wMDQgNDQuNDc1LTguMjU4IDU3Ljc1MiA3Ljc1MyAxMy4yNDQgMjcuNDg4IDIwLjg1IDU0LjExOSAyMC44NWg4MjAuMzA2YzI2LjY3MyAwIDQ2LjM3Mi03LjU2NSA1NC4xMTktMjAuODUgNy43MDQtMTMuMjQyIDQuNzEtMzQuMjgtOC4yOTMtNTcuNzFsLTQxNC4zMTgtNzQ2LjZjLTEyLjYxOC0yMi43NjYtMjguMzA2LTM2LjM2MS00MS44OTMtMzYuMzYxeiBtMC4yMzMgNTg2LjM0OGMtMjEuNDg4IDAtMzguOTMtMTcuNTE2LTM4LjkzLTM5LjE4MnYtMzUyLjYzYzAtMjEuNjMgMTcuNDQyLTM5LjE4MyAzOC45My0zOS4xODMgMjEuNDkyIDAgMzguOTM0IDE3LjU1NCAzOC45MzQgMzkuMTgzdjM1Mi42M2MwIDIxLjY2Ni0xNy40NDIgMzkuMTgyLTM4LjkzNCAzOS4xODJ6IG0tMC4yNzkgMTkxLjUyM2MzMy41MDMgMCA2MC42NjMtMjcuMzgyIDYwLjY2My02MS4xNiAwLTMzLjc3Ny0yNy4xNi02MS4xNTktNjAuNjYzLTYxLjE1OXMtNjAuNjYzIDI3LjM4Mi02MC42NjMgNjEuMTZjMCAzMy43NzcgMjcuMTYgNjEuMTYgNjAuNjYzIDYxLjE2eiIgcC1pZD0iMzQ3NSIgZmlsbD0iI2Q4MWUwNiI+PC9wYXRoPjwvc3ZnPg=="
};

export default {
  components: {
    ECharts,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  watch: {
    optionData: {
      handler(newVal, oldVal) {
        this.optionData = newVal;
        this.options.xAxis.data = this.optionData.xAxisData;
        this.options.series[0].data = this.optionData.seriesData;
        this.options.series[0].markPoint = this.markPoints(
          this.optionData.markData
        );
      },
    },
  },
  data() {
    return {
      options: {
        color: ["#a69ef6"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255, 255, 255)",
          textStyle: {
            color: "#000",
          },
          extraCssText: "box-shadow: 0 0 21px rgba(64, 54, 179, 0.19)",
          formatter: function (params) {
            let item = params[0].data;
            let name = params[0].name;
            return `${name} <br/> ${item} 次 / 分钟`;
          },
        },
        grid: {
          top: "5%",
          left: "7%",
          right: "3%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            interval: 999,
            showMinLabel: true,
            showMaxLabel: true,
          },
          data: [],
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            fontFamily: "Microsoft YaHei",
          },
        },
        series: [
          {
            type: "line",
            symbol: "none",
            data: [],
          },
        ],
        dataZoom: {
            type: 'slider',
            start: 0,
            end: 10,
            bottom: 0,
            minSpan: 10,
            height: 20
        }
      },
    };
  },
  methods: {
    markPoints(points) {
      if (!points) return;
      let mkps = points.map((item) => {
        return {
          xAxis: item.xAxis,
          yAxis: item.yAxis,
          symbol: "image://" + symbolType[item.warn],
          symbolSize: 10,
          symbolOffset: ['0','-70%'],
          animation: true,
          label: {
            show: true,
            color: "#000",
          },
          itemStyle: {
            color: "#f00",
          },
        };
      });
      const mkp = { data: mkps };
      return mkp;
    },
  },
};
</script>
